<template>
  <div class="choiceData">
    <!-- <label @click="buttonProjectManagerFn" class="choiceDataBtn"> -->
    <label @click="dlgVisible = true" class="choiceDataBtn">
      <slot name="button"> </slot>
    </label>
    <el-dialog
      class="choiceDataCon"
      title="请选择"
      :visible.sync="dlgVisible"
      :append-to-body="true"
      width="60%"
    >
      <div class="selected-list">
        <div class="mainList">
          <!-- <slot name="search" :params="parameter.params" :search="pageChangeHandler"> </slot> -->

          <div class="mainList_content">
            <div class="mainList_content_sub">
              <div class="mainList_operation_search" style="margin-bottom: 8px">
                <span style="margin-right: 8px"
                  >合同名称:
                  <el-select
                    v-model="filter.agreementName"
                    clearable
                    filterable
                    placeholder="请输入或选择合同名称"
                    class="ipt_width"
                    style="width: 60%"
                  >
                    <el-option
                      v-for="item in agreementList"
                      :key="item.id"
                      :label="item.agreementName"
                      :value="item.agreementName"
                    >
                    </el-option>
                  </el-select>
                </span>

                <el-button
                  type="primary"
                  size="medium"
                  plain
                  icon="el-icon-search"
                  @click="pageChangeHandler(1)"
                  >搜索</el-button
                >
              </div>
              <el-table
                ref="multipleTable"
                :border="true"
                :data="selectedList"
                tooltip-effect="dark"
                height="string"
                v-loading="selectedListLoad"
              >
                <slot name="tableColumn-left"></slot>

                <el-table-column label="操作" width="100" align="center">
                  <template slot-scope="scope">
                    <el-button
                      type="primary"
                      size="mini"
                      @click="
                        $emit('choiceDataChange', scope.row)
                        dlgVisible = false
                      "
                      :disabled="scope.row.id == agreementSelsectId"
                      >{{ scope.row.id == agreementSelsectId ? '已选' : '选择' }}</el-button
                    >
                  </template>
                </el-table-column>
                <slot name="tableColumn"></slot>
              </el-table>
              <el-pagination
                @current-change="pageChangeHandler"
                @size-change="handleSizeChange"
                :current-page="filter.pageNow"
                :page-size="filter.pageSize"
                prev-text="上一页"
                next-text="下一页"
                :total="filter.total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>

      <div slot="footer" name="footer" class="dialog-footer">
        <el-button type="info" plain @click="dlgVisible = false">返回</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ChoiceContract',
  components: {},
  props: {
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    agreementSelsectId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dlgVisible: false,
      selectedListLoad: false,
      selectedList: [],
      filter: {
        pageNow: 1,
        total: 1,
        pageSize: 50,
        agreementName: null,
      },

      agreementList: [],
    }
  },
  computed: {
    ...mapState({
      projectInformation: state => state.project.projectInformation,
    }),
  },
  watch: {
    options: {
      deep: true,
      immediate: true,
      handler: function (val) {},
    },
    dlgVisible: {
      immediate: true,
      handler: function (val, oldVal) {
        if (val && this.options && !this.options.projectId) {
          this.$message.warning('请先选择项目')
          this.dlgVisible = false
        } else if (val) {
          this.getAgreement()
          this.$api.agreement
            .listByProject({ pageSize: 10000, pageNow: 1, projectId: this.options.projectId })
            .then(res => {
              this.agreementList = res?.data
            })
            .catch(err => {
              console.log(err)
            })
        }
      },
    },
  },
  created() {},
  methods: {
    async getAgreement() {
      this.selectedListLoad = true
      try {
        this.filter.projectId = this.options.projectId
        const res = await this.$api.agreement.listByProject(this.filter)
        this.selectedList = res?.data?.deepClone()
        this.filter.total = res?.data?.length
        this.selectedListLoad = false
      } catch (error) {
        this.selectedListLoad = false
      }
    },
    pageChangeHandler(val) {
      this.filter.pageNow = val
      this.getAgreement()
    },
    handleSizeChange(val) {
      this.filter.pageSize = val
      this.filter.pageNow = 1
      this.getAgreement()
    },
  },
}
</script>
<style scoped lang="scss"></style>
